/* Estilos generales */

body {
  font-family: sans-serif;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3 {
  font-weight: bold;
}

p {
  font-size: 16px;
  line-height: 1.5;
}

a {
  text-decoration: none;
  color: #000;
}

/* Estilos para el header */

header {
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
}

.logo {
  float: left;
  padding: 10px;
}

nav {
  float: right;
  margin: 0;
  padding: 10px;
}

nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

nav li {
  display: inline-block;
  margin: 0 10px;
}

nav a {
  color: #000;
  font-size: 16px;
}

.menu-toggle {
  display: none;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  z-index: 11;
}

.menu-toggle span {
  display: block;
  width: 100%;
  height: 4px;
  background-color: #000;
  margin: 5px 0;
}

@media (max-width: 768px) {
  nav {
    display: none;
  }

  .menu-toggle {
    display: block;
  }
}

.menu-open nav {
  display: block;
}
