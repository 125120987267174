* {
  box-sizing: border-box;
}

body {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 1.6;
}
.logo img {
  opacity: 0.4;
  border-radius: 50%;
  height: 100px;
  width: 100px;
  overflow: hidden;
}

.logo-footer img {
  height: 200px;
  width: 200px;
}

.home-wrapper {
  background:
    linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("fondoPantalla3.jpg") no-repeat center top;
  background-size: cover;
  color: white;
}

.blog {
  background:
    linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("fondoBlog.jpg") no-repeat center top;
  background-size: cover;
  color: white;
}

header {
  padding: 10px 0;
  width: 100%;
  background-color: transparent;
  position: absolute;
}

header img {
  width: 200px;
}

header nav {
  float: right;
  margin-top: 10px;
}

header nav a {
  color: white;
  font-weight: bold;
  text-decoration: none;
  margin-left: 10px;
  padding: 10px 15px;
}

header nav a.active {
  background: #fed136;
  border-radius: 3px;
}

#home {
  text-align: center;
  padding: 200px 0 150px;
}

#home h1 {
  font-size: 68px;
  font-weight: normal;
  line-height: 1.2;
  margin-bottom: 40px;
}

#home .divider {
  width: 80px;
  border-top: 5px solid #fed136;
  margin: 0 auto;
}

#home p {
  font-size: 24px;
  margin-top: 40px;
}

#home .social {
  margin-top: 80px;
}
#home .social a {
  color: white;
  display: inline-block;
  border-radius: 50%;
  background-color: black;
  font-size: 24px;
  font-weight: bold;
  height: 50px;
  width: 50px;
  line-height: 50px;
  margin-right: 10px;
}

#home .social a:hover {
  background-color: #fed136;
}

li:hover {
  color: #fed136;
  background-color: #fed136;
  cursor: pointer; /* Indicar que el elemento es interactivo */
}

#servicios .box {
  display: inline-block;
  text-align: center;
  width: 33%;
  padding: 10px;
}

#servicios .box img {
  border-radius: 6%;
}

#portafolio .box img {
  border-radius: 6%;
}

#video {
  background-color: black;
  text-align: center;
  padding: 30px 0;
}

#portafolio {
  background-color: whitesmoke;
}

#portafolio .box {
  display: inline-block;
  text-align: center;
  width: 31%;
  margin: 1%;
  background-color: white;
}

#portafolio .box img {
  width: 100%;
}

#portafolio .box p {
  color: #666;
  padding: 6px;
}

#contacto {
  background-color: #303030;
  background-image: url("map-image.png");
  background-position: center;
  background-repeat: no-repeat;
  text-align: center;
}

#contacto h2 {
  color: white;
}

#contacto input {
  padding: 20px;
  width: 400px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

#contacto textarea {
  margin: 20px 0;
  padding: 20px;
  width: 400px;
}

#contacto button {
  background-color: #fed136;
  padding: 20px 40px;
  border-color: #fed136;
  border-radius: 3px;
  color: white;
  font-size: 18px;
  line-height: 1;
  font-weight: bold;
}

footer {
  text-align: center;
  font-size: 18px;
}

footer h2 {
  margin-top: 0;
}

.social {
  margin-right: 10px;
}

section {
  padding: 80px 0;
}
section h2 {
  font-size: 32px;
  text-align: center;
  margin-bottom: 80px;
}
.container {
  width: 992px;
  margin-left: auto;
  margin-right: auto;
}

.name {
  margin-bottom: 10px;
}

.float {
  width: 80px;
  height: 80px;
  font-size: 50px;
  position: fixed;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}
.float:hover {
  text-decoration: none;
  color: #25d366;
  background-color: #fff;
}

.my-float {
  margin-top: 16px;
}

.image-gallery img {
  border-radius: 10px;
}

@keyframes vibrar {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(4px);
  }
  50% {
    transform: translateX(-4px);
  }
  75% {
    transform: translateX(4px);
  }
  100% {
    transform: translateX(0);
  }
}

.boton-vibracion {
  animation: vibrar 0.2s linear 20;
}

@media (max-width: 600px) {
  * {
    box-sizing: border-box;
  }

  body {
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    line-height: 1.6;
  }
  .container h1 {
    font-size: 8px;
  }
  .logo img {
    opacity: 0.4;
    border-radius: 50%;
    height: 70px;
    width: 70px;
    overflow: hidden;
  }

  .home-wrapper {
    background:
      linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
      url("fondoPantalla3.jpg") no-repeat center top;
    background-size: cover;
    color: white;
    width: 100%;
  }

  header {
    padding: 10px 0;
    width: 100%;
    background-color: transparent;
    position: absolute;
  }

  header img {
    width: 200px;
  }

  header nav {
    float: right;
    margin-top: 10px;
  }

  header nav a {
    color: white;
    font-weight: bold;
    text-decoration: none;
    margin-left: 10px;
    padding: 10px 15px;
  }

  header nav a.active {
    background: #fed136;
    border-radius: 3px;
  }

  #home {
    text-align: center;
    padding: 200px 0 150px;
  }

  #home h1 {
    font-size: 58px;
    font-weight: normal;
    line-height: 1.2;
    margin-bottom: 40px;
  }

  #home .divider {
    width: 80px;
    border-top: 5px solid #fed136;
    margin: 0 auto;
  }

  #home p {
    font-size: 18px;
    margin-top: 40px;
  }

  #home h1 {
    font-size: 26px;
    margin-top: 40px;
  }

  #home .social {
    margin-top: 80px;
  }
  #home .social a {
    color: white;
    display: inline-block;
    border-radius: 50%;
    background-color: black;
    font-size: 14px;
    font-weight: bold;
    height: 50px;
    width: 50px;
    line-height: 50px;
    margin-right: 10px;
  }

  #home .social a:hover {
    background-color: #fed136;
  }

  li:hover {
    color: #fed136;
    background-color: #fed136;
    cursor: pointer; /* Indicar que el elemento es interactivo */
  }

  #servicios .box {
    display: inline-block;
    text-align: center;
    width: 33%;
    padding: 10px;
  }

  #servicios .box img {
    border-radius: 6%;
  }

  #portafolio .box img {
    border-radius: 6%;
  }

  #video {
    background-color: black;
    text-align: center;
    padding: 10px 0;
  }

  .video iframe {
    width: 280px;
    height: 200px;
  }
  #portafolio {
    background-color: whitesmoke;
  }

  #portafolio .box {
    display: inline-block;
    text-align: center;
    width: 91%;
    margin: 10%;
    background-color: white;
  }

  #portafolio .box img {
    width: 100%;
  }

  #portafolio .box p {
    color: #666;
    padding: 6px;
  }

  #contacto {
    background-color: #303030;
    background-image: url("map-image.png");
    background-position: center;
    background-repeat: no-repeat;
    text-align: center;
    width: 100%;
  }

  #contacto h2 {
    color: white;
  }

  #contacto input {
    padding: 20px;
    width: 200px;
    border: 1px solid #ccc;
    border-radius: 3px;
  }

  #contacto textarea {
    margin: 20px 0;
    padding: 20px;
    width: 200px;
  }

  #contacto button {
    background-color: #fed136;
    padding: 20px 40px;
    border-color: #fed136;
    border-radius: 3px;
    color: white;
    font-size: 12px;
    line-height: 1;
    font-weight: bold;
  }

  footer {
    text-align: center;
    font-size: 18px;
  }
  .social {
    margin-right: 10px;
  }
  footer h2 {
    margin-top: 0;
  }

  iframe {
    width: 100%;
    height: 215;
  }

  section {
    padding: 80px 0;
  }
  section h2 {
    font-size: 22px;
    text-align: center;
    margin-bottom: 80px;
  }
  .container {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }

  .name {
    margin-bottom: 10px;
  }

  .float {
    position: fixed;
    width: 80px;
    height: 80px;
    bottom: 40px;
    right: 40px;
    background-color: #25d366;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    font-size: 50px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
  }
  .float:hover {
    text-decoration: none;
    color: #25d366;
    background-color: #fff;
  }

  .my-float {
    margin-top: 16px;
  }
}
